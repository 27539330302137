import {
  Divider,
  Drawer,
  IconButton,
  Stack,
  styled,
  ClickAwayListener,
} from "@mui/material";
import React, { ReactNode } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTranslations } from "next-intl";
import { useTheme } from "@patron/utils/theme";
import { CustomTypographyComponent } from "@patron/ui/text";

interface SideSheetCompomentProps {
  children: ReactNode;
  drawerOpen: boolean;
  drawerTitle: string;
  width?: number;
  useClickAwayOff?: boolean;
  hideOverflow?: boolean;
  height?: any;
  onDrawerClosed: () => void;
}

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export const SideSheetCompoment = ({
  children,
  drawerOpen,
  hideOverflow,
  height,
  useClickAwayOff,
  onDrawerClosed,
  drawerTitle,
  width,
}: SideSheetCompomentProps) => {
  const drawerWidth = width || 300;
  const t = useTranslations("common");
  const theme = useTheme();

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={() => (useClickAwayOff ? null : onDrawerClosed())}
    >
      <Drawer
        sx={{
          // width: drawerWidth,
          overflow: hideOverflow ? "hidden" : "auto",
          height: height || "100%",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            overflow: hideOverflow ? "hidden" : "auto",
            height: "99%",
            marginTop: "45px",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          },
        }}
        variant="persistent"
        anchor="right"
        onClose={onDrawerClosed}
        open={drawerOpen}
      >
        <DrawerHeader>
          <IconButton onClick={onDrawerClosed}>
            {theme.direction === "ltr" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
          <CustomTypographyComponent>
            {t(drawerTitle)}
          </CustomTypographyComponent>
        </DrawerHeader>

        <Divider />
        <Stack id="SideSheetCompomentInnerStack" width="100%" padding={2}>
          {children}
        </Stack>
      </Drawer>
    </ClickAwayListener>
  );
};

export default SideSheetCompoment;
