import React from "react";
import { AppContext } from "@patron/utils/context";
import updateLocale from "dayjs/plugin/updateLocale";
import dayjs from "dayjs";

export const SetDefaultLocale = () => {
  const { localAppState } = React.useContext(AppContext);
  const { defaultStartWeek } =
    localAppState.tenantCompanyDetails?.config.global || 0;
  dayjs.locale("nl");
  dayjs.extend(updateLocale);
  dayjs.updateLocale("nl", {
    weekStart: defaultStartWeek,
  });
};
